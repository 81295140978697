import { BsYoutube } from 'react-icons/bs'
export default function ContactDetailsPanel() {
  const socials = [
    { path: 'https://www.youtube.com/@eternallifeinternationalch9146', element: <BsYoutube size={42} /> }]
  
  return (
    <span className='flex flex-col items-center justify-end md:justify-center space-y-4'>
        <span className='flex flex-col w-full text-center space-y-8'>
        <p className="flex font-bold text-4xl justify-center text-center">Contact Us</p>
        <p className="flex font-medium justify-center text-center">Email : info@elichurch.com</p>
        <p className="flex font-medium justify-center text-center">Telephone : 015 230 0002 </p>
        <p className="flex font-medium justify-center text-center"></p>
        <p className="flex font-medium justify-end md:justify-start">Address : Plot 26 Palmietfontein, Polokwane, 0699</p>
        </span>
        {/* <span className='flex flex-row w-full justify-end md:justify-start'>
          {
            socials.map((social: any) => {
              return <a key={social.path} href={social.path} rel="noreferrer" target="_blank">
                {social.element}
            </a>})
          }
        </span> */}
    </span>
  )
}
