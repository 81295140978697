import { useEffect, useState } from "react"
import sanityClient from "../sanityClient"
import { NavLink } from "react-router-dom"

export default function Home() {
  const [image, setImage] = useState('')
  const [overlayText, setOverlayText] = useState('')
  useEffect(() => {
    sanityClient
      .fetch(
				`*[_type == "homePage" && title == "Hero Content"]{
            description,
            "imageUrl": image.asset->url
        }`
			)
      .then((data) => data.map((d: any) => {
        setOverlayText(d.description)
        return setImage(d.imageUrl);
      }))
			.catch(console.error);
  },[])
  return (
      <span className='flex justify-center items-center h-screen w-screen'>
        <span className="flex justify-center items-center w-full h-full" >
          <img className="object-cover lg:object-fill flex h-full w-full lg:w-full lg:h-full" src={image} alt="Church_Prayer_logo" />
        </span>
        <span className="flex flex-col w-full h-full absolute items-center justify-center animate-fadeInDown15 space-y-4">
          <span className="flex w-full justify-center">
            <p className="text-center text-white font-bold text-6xl lg:text-8xl w-2/3">{overlayText}</p>
        </span>
        <span>
          <p className="text-white">SUNDAY SERVICE | 09:00</p>
        </span>
          <span className="flex justify-center flex-col lg:flex-row  space-x-0 lg:space-x-4 space-y-4 lg:space-y-0">
            <NavLink to='/Events' className="flex items-center justify-center w-52 bg-sky-700 p-3 rounded-full hover:scale-110 hover:opacity-80 transition text-white">EVENTS</NavLink> 
            <NavLink to='/Services' className="flex items-center justify-center w-52 bg-sky-700 p-3 rounded-full hover:scale-110 hover:opacity-80 transition text-white">SERVICES</NavLink> 
            {/* <NavLink to='/Giving/BuildingFund' className="flex items-center justify-center w-52 bg-sky-700 p-3 rounded-full hover:scale-110 hover:opacity-80 transition text-white">BUILDING FUND</NavLink>  */}
          </span>
        </span>
      </span>
  )
}
