import { useEffect, useState } from "react";
import sanityClient from "../../sanityClient";
import { PortableText } from "@portabletext/react";

export default function TitheAndOffering() {

  const [title, setTitle] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('')
  const [bankingDetails, setBankingDetails] = useState([])
  const [bankingReference, setBankingReference] = useState('')
  useEffect(() => {
    sanityClient
      .fetch(
				`*[_type == "givingPage" && title == "GIVING"]{
            title,
            bankingDetails,
            bankingReference,
            "backgroundImage" : backgroundImage.asset->url
        }`
			)
      .then((data) => data.map((d: any) => {
        setTitle(d.title)
        setBackgroundImage(d.backgroundImage)
        setBankingDetails(d.bankingDetails)
        setBankingReference(d.bankingReference)
        return console.log(data);
      }))
			.catch(console.error);
  }, [])

  return (
    <span className="flex flex-col w-screen h-full items-center justify-center">

      <span className='flex w-full h-auto items-center justify-center'>
        <span className="flex justify-center items-center w-full h-96">
          <img className="flex object-cover h-auto w-full lg:w-full lg:h-auto" src={backgroundImage} alt=""/>
        </span>
        <p className="flex h-auto absolute text-white font-bold text-6xl items-center justify-center text-center w-2/3 md:w-full">{ title }</p>
      </span>

      <span className='flex flex-col w-full h-96 items-center justify-center bg-sky-700 text-center text-white'>
        <p className="font-bold">OFFERING</p>
        <span className="flex flex-col w-9/12">
          <PortableText value={bankingDetails} />
        </span>
        <p>Reference : Your Name</p>
      </span> 
        

      <span className='flex flex-col w-full h-96 items-center justify-center bg-white text-center text-sky-700'>
        <p className="font-bold">BUILDING FUND</p>
        <span className="flex flex-col w-9/12">
          <PortableText value={bankingDetails} />
        </span>
        <p>{bankingReference}</p>
      </span>



      <span className='flex flex-col w-full h-full items-center justify-center bg-sky-700 text-center p-6'>
        <span className="w-2/3 text-white pt-4"> <p className="font-bold">Give Online</p><br />
          The safe and flexible options is the easiest way to give at Eternal Life International Church! This method allows you to give from your checking/savings account through INSTANT EFT, debit/credit card, Snapscan or Zapper. Here you can easily manage your giving amount. Simply enter the amount below and click where it says "Pay Now” or choose one of the other methods below.
          <br /><br /><br /><br />
          <span className="flex items-center justify-center w-auto bg-white p-3 rounded-full hover:scale-110 hover:opacity-80 transition text-sky-700">
            ONLINE GIVING METHODS WILL BE ADDED SOON
          </span> 
        </span> 
      </span>
    </span>
  )
}
