import { useEffect, useState } from "react"
import { BsYoutube } from "react-icons/bs"
import { AiFillFacebook } from "react-icons/ai"
import FooterItem from "./FooterItem"

export default function Footer() {
  const [footerItems, setFootItems] = useState([
    { header: "Location", blockText: "Plot 26 Palmietfontein Polokwane" },
    {header: "Main Service", blockText: "Sunday | 09:00"},
    {header: "Contact", blockText: "Ps. Tebogo Maepa | 081 539 8386"}
  ] as any[])
  useEffect(() => {
    
  },[])
  return (
    <span className='flex flex-col h-full w-full bg-sky-700 items-center justify-center space-y-8 p-6 border-t-2 border-white'>

      <span className="flex flex-col w-5/6 lg:w-2/3 items-center justify-center lg:flex-row space-x-0 lg:space-x-6 space-y-6 lg:space-y-0">
        {
          footerItems.map((fi: any) => {
            return <FooterItem key={fi.header} header={fi.header} blockText={fi.blockText} />
          })
        }
      </span>

      <span className="flex flex-row items-center justify-center h-auto w-full space-x-6 text-white">
        <span className="hover:cursor-pointer hover:scale-110 transition"><BsYoutube size={50} /></span>
        <span className="hover:cursor-pointer hover:scale-110 transition"><AiFillFacebook size={50}/></span>
      </span>
    </span>
  )
}
