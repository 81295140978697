import { Suspense } from "react";
import NavigationRoutes from "./Navigation/NavigationRoutes";
import Footer from "./Footer/Footer";

export default function BodyPanel() {
  return (
        <span className='flex flex-col h-auto w-screen absolute'>
            <Suspense fallback={<div className="container">Loading...</div>}>
              <NavigationRoutes/>
            </Suspense>
        <Footer/>
        </span>  
  )
}
