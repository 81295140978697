import { useEffect, useState } from "react"
import { MinistryDetail } from "../../models/MinistryDetail"
import InfoPanel from "../../components/InfoPanel"
import sanityClient from "../../sanityClient"

export default function Ministries() {
    const [backgroundImage, setBackgroundImage] = useState('')
    const [title, setTitle] = useState('');
    const [ministries, setMinistries] = useState([] as MinistryDetail[])
  useEffect(() => {
      sanityClient
      .fetch(
				`*[_type == "ministriesPage" && title == "Ministries"]{
            title,
            "backgroundImage" : backgroundImage.asset->url
        }`
			)
      .then((data) => data.map((d: any) => {
        setTitle(d.title)
        setBackgroundImage(d.backgroundImage)
        return getMinistries();
      }))
			.catch(console.error);
    }, [])
    
  const getMinistries = () => {
    sanityClient
      .fetch(
				`*[_type == "ministry"]{
          "heading" : title,
          description,
          contactName,
          contactNumber
        }`)
      .then((data) => {
        setMinistries(data)
        return console.log(data)
      })
			.catch(console.error);
  }
  
  
  return (
    <span className="flex flex-col w-screen h-auto items-center justify-center">
      <span className='flex w-full h-full items-center justify-center'>
        <span className="flex justify-start items-center w-full h-96">
          <img className="flex object-cover h-full w-full lg:w-full lg:h-auto" src={backgroundImage} alt=""/>
        </span>
        <p className="flex h-auto absolute text-white font-bold text-5xl lg:text-6xl items-center justify-center text-center w-2/3 md:w-full">{ title }</p>
      </span>

      <span className='flex flex-col w-full h-full items-center justify-center bg-white text-center space-y-8 p-12'>
        <span className="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-3 gap-6">
        {
            ministries.map((ministry: MinistryDetail) => {
              return <InfoPanel key={ministry.id} heading={ministry.heading} description={ministry.description} contactName={ministry.contactName} contactNumber={ministry.contactNumber} />
          })  
        }
        </span>
      </span>
    </span>
  )
}
