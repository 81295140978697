import {HiArrowCircleRight, HiArrowCircleLeft} from 'react-icons/hi'
import NavigationList from './NavigationList'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useState } from 'react';
import { CiMenuFries } from "react-icons/ci";

export default function NavigationPanel() {
  const [openNav, setOpenNav] = useState(false);
  return (
    <>
      <span className={`text-sky-200 h-auto w-auto duration-500 fixed right-8 top-12`}>
        <CiMenuFries size={32} className='hover:cursor-pointer hover:scale-110 transition ease-in-out' onClick={()=>setOpenNav(true)}/>
      </span>
      {
        openNav &&
        <div className='fixed flex flex-col w-screen h-screen items-center justify-center bg-sky-700 z-50'>
            <span className={`text-white h-auto w-auto duration-500 fixed right-8 top-12`}>
              <IoIosCloseCircleOutline  size={32} className='hover:cursor-pointer hover:scale-110 transition ease-in-out' onClick={()=>setOpenNav(false)}/>
            </span>
            <NavigationList setOpenNav={setOpenNav} openNav={openNav} />
        </div>
      }
    </>
  )
}
