import { useEffect, useState } from "react"
import sanityClient from "../sanityClient";
import { PortableText } from "@portabletext/react";

export default function AboutOurChurch() {
    //Get about page content
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState([] as any[]);
  const [ourLeaders, setOurLeaders] = useState([] as any[]);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [leadersImage, setLeadersImage] = useState('');

  useEffect(() => {
    sanityClient
      .fetch(
				`*[_type == "aboutPage" && title == "OUR STORY"]{
            title,
            description,
            ourLeaders,
            "backgroundImage" : backgroundImage.asset->url,
            "leadersImage" : leadersImage.asset->url
        }`
			)
      .then((data) => data.map((d: any) => {
        setTitle(d.title)
        setDescription(d.description)
        setOurLeaders(d.ourLeaders)
        setBackgroundImage(d.backgroundImage)
        setLeadersImage(d.leadersImage)
        return console.log(d)
      }))
			.catch(console.error);
  },[])
  return (
    <span className="flex flex-col w-screen h-full items-center justify-center">

      <span className='flex w-full h-full items-center justify-center'>
        <span className="flex justify-center items-center w-full h-96">
          <img className="flex object-cover h-auto w-full lg:w-full lg:h-auto" src={backgroundImage} alt=""/>
        </span>
        <p className="flex h-auto absolute text-white font-bold text-6xl items-center justify-center text-center w-2/3 md:w-full">{ title }</p>
      </span>

      <span className='flex flex-col w-full h-auto items-center justify-center bg-white text-center p-12 space-y-6'>
        <p className="flex w-8/12 lg:w-full text-sky-700 font-bold text-4xl lg:text-6xl text-center items-center justify-center">Established in the Gospel</p>
        <span className="flex w-11/12"><PortableText value={description}/></span>
      </span>

      <span className='flex flex-col w-full h-auto items-center justify-center bg-sky-700 text-center p-6'>
        <p className="w-2/3 text-white font-bold text-4xl">Who are our leaders?<br /><br /><br /></p>
        <span className="flex flex-col lg:flex-row w-2/3 text-white items-center justify-center" >
          <img className="rounded-md" height={320} width={320} src={ leadersImage} alt=""/>
          <span className="flex flex-col p-4"><PortableText value={ourLeaders}/></span>
        </span>
      </span>
      
    </span>
  )
}
