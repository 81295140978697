import NavigationPanel from "./Navigation/NavigationPanel";

export default function HeaderPanel() {
  return (
    <span className="flex z-10 w-full">
      <span className="flex flex-end gap-6 z-10  animate-fadeInLeft1 ">
        <NavigationPanel />
      </span>
    </span>
  )
}
