import './App.css';
import BodyPanel from './components/BodyPanel';
import FloatingWhatsappContainer from './components/Contact/FloatingWhatsappContainer';
import Footer from './components/Footer/Footer';
import HeaderPanel from './components/HeaderPanel';
import LogoPanel from './components/Navigation/LogoPanel';

function App() {
  return (
    <>
      <HeaderPanel />
      <BodyPanel />
      <span className='flex absolute left-0'>
        <LogoPanel/>
      </span>
      <span className='fixed bottom-16 right-16 animate-fadeInUp05'>
        <FloatingWhatsappContainer />
      </span>
    </>
  );
}

export default App;
