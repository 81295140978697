import { Routes, Route } from 'react-router-dom';
import AboutOurChurch from '../../Pages/AboutOurChurch';
import ContactUs from '../../Pages/ContactUs';
import Home from '../../Pages/Home';
import NoMatch from '../../Pages/NoMatch';
import TitheAndOffering from '../../Pages/Giving/TitheAndOffering';
import Events from '../../Pages/Events';
import Ministries from '../../Pages/Connect/Ministries';
import Services from '../../Pages/Services';

export default function NavigationRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/AboutOurChurch" element={<AboutOurChurch />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Connect/Ministries" element={<Ministries />} />
        <Route path="/Events" element={<Events />} />
        <Route path="/Giving/TitheAndOffering" element={<TitheAndOffering />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
    
  )
}
