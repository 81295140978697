import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import Loading from "../Loading";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function ContactFormPanel() {

  const form = useRef({} as any)
  const [loading, setLoading] = useState(false);

  const sendEmail = (e: any) => {
    e.preventDefault()
    setLoading(true)
    console.log(process.env.REACT_APP_EMAIL_SERVICE_ID )
    emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID ?? '', process.env.REACT_APP_EMAIL_TEMPLATE_ID ?? '', form.current,  process.env.REACT_APP_EMAIL_PUBLIC_KEY ?? '')
      .then((result:any) => {
        setLoading(false);
        console.log(result.text);
        e.target?.reset();
        toast("Thank you for reaching out. We will be in touch soon🙏🏾")
      }, (error:any) => {
        setLoading(false);
        console.log(error.text);
        toast("Something went wrong while trying to send your message 🥴. Please try again")
      });
  };

  return (
    <>
            <form  ref={form} onSubmit={sendEmail} className="flex flex-col w-9/12 h-auto items-center justify-center p-6 bg-white rounded-md space-y-2" >

              <span className="flex flex-row w-full space-x-4">
                  <label className="flex flex-col w-11/12">
                        <span className="flex flex-row text-sky-700 font-semibold">First Name</span>
                        <input id="firstName" name="firstName"  type="text" placeholder="" required className="flex items-start w-full px-2 py-2 mt-2 rounded-md shadow-sm border-indigo-300 ring ring-indigo-200 ring-opacity-50 text-black"/>
                  </label>
          
                    <label className="flex flex-col w-11/12">
                        <span className="flex flex-row text-sky-700 font-semibold">Last Name</span>
                        <input id="lastName" name="lastName"  type="text" required className="flex items-start w-full px-2 py-2 mt-2 rounded-md shadow-sm border-indigo-300 ring ring-indigo-200 ring-opacity-50 text-black" placeholder=""/>
                    </label>
              </span>
        
              <span className="flex flex-row w-full items-start justify-start">
                    <label className="flex flex-col w-11/12">
                        <span className="flex flex-row text-sky-700 font-semibold">Phone Number</span>
                        <input id="phoneNumber" name="phoneNumber" type="number" className="flex items-start w-full px-2 py-2 mt-2 rounded-md shadow-sm border-indigo-300 ring ring-indigo-200 ring-opacity-50 text-black" placeholder=""/>
                    </label>
              </span>
        
              <span className="flex flex-row w-full">
                <label className="flex flex-col w-full">
                  <span className="text-sky-700 font-semibold w-full">Message</span>
                  <textarea id="message" name="message" required className="flex w-full mt-2 px-2 py-2 rounded-md border-indigo-300 ring ring-indigo-200 text-black" rows={5}></textarea>
                </label>
              </span>
        

              <span className="p-2">
                <button type="submit" className="flex p-2 bg-yellow-500 rounded-lg hover:bg-yellow-400 text-white hover:text-black font-semibold">
            {
              loading ?
                <Loading text="Sending message..."/> :
                 "Contact Us"   }
                </button>
              </span>
        </form>
        <ToastContainer />
      </>
  )
}
