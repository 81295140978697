export default function ChurchEvent(props: any) {
  const { image, heading, date, description} = props;
  return (
    <span className='flex flex-col md:flex-row h-full w-10/12 shadow-lg rounded-lg hover:scale-105 transition'>
      <span className="flex h-full w-full md:w-1/2 bg-yellow-700">
        <img className="flex object-cover w-full h-full" src={image} alt="Polokwane_Church" />
      </span>
      <span className="flex flex-col items-center justify-center text-left w-full h-auto p-8 lg:p-0">
        <p className="font-bold text-xl">{heading }</p>
        <p>{ date }</p>
        <span>{description }</span>
      </span>
    </span>
  )
}
