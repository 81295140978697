import { useEffect, useState } from 'react'
import ChurchEvent from '../components/Events/ChurchEvent'
import { ChurchEventDetail } from '../models/ChurchEventDetail'
import sanityClient from '../sanityClient'

export default function Events() {
  const [events, setEvents] = useState([] as ChurchEventDetail[])
  const [title, setTitle] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('')
  useEffect(() => {
    sanityClient
      .fetch(
				`*[_type == "eventsPage" && title == "Events Background"]{
            "backgroundImage" : backgroundImage.asset->url
        }`
			)
      .then((data) => data.map((d: any) => {
        setTitle(d.title)
        setBackgroundImage(d.backgroundImage)
        return getEvents();
      }))
			.catch(console.error);
  }, [])

  const getEvents = (() => {
    sanityClient
      .fetch(
				`*[_type == "event"]{
  "heading" : title,
  description,
  date,
  "image" : eventImage.asset->url
}`
			)
      .then((data) => {
        setEvents(data)
        return console.log(data)
      })
			.catch(console.error);
  })
  return (
    <span className="flex flex-col w-screen h-full items-center justify-center">

      <span className='flex w-full h-full items-center justify-center'>
        <span className="flex justify-start items-center w-full h-40">
          <img className="flex object-cover h-full w-full lg:w-full lg:h-full" src={ backgroundImage } alt=""/>
        </span>
        <p className="flex h-auto absolute text-white font-bold text-6xl items-center justify-center text-center w-2/3 md:w-full">{ title}</p>
      </span>

      <span className='flex flex-col w-full h-auto items-center justify-center bg-white text-center space-y-8 p-12 overflow-scroll'>
        {
          events?.map((event: ChurchEventDetail) => {
            return <ChurchEvent key={event.heading} image={event.image} heading={event.heading} date={event.date} description={event.description} />
          })
        }
      </span>
    </span>
  )
}
