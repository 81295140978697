import { FloatingWhatsApp } from "react-floating-whatsapp";

export default function FloatingWhatsappContainer() {
  return (
        <>
        <FloatingWhatsApp className='z-40' phoneNumber='+27820997953' accountName='Eternal Life International Church' avatar='/whatsapp_logo.svg'
                          statusMessage='Let us know how we can help you' allowClickAway={true}/>
    </>
  )
}
