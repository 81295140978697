import ContactDetailsPanel from "../components/Contact/ContactDetailsPanel";
import ContactFormPanel from "../components/Contact/ContactFormPanel";

export default function ContactUs() {
  return (
    <span className='flex flex-col w-full h-screen justify-center items-center bg-sky-700 text-white'>
      <span className="flex flex-col w-full h-full items-center justify-center md:h-auto lg:flex-row">
        <span className="flex w-full h-auto items-center justify-center pt-40 pb-4 md:pt-0"><ContactDetailsPanel /></span>
        <span className="flex w-10/12 h-full items-center justify-center"> <ContactFormPanel/></span>
      </span>
    </span>
  )
}
