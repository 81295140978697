import { useEffect, useState } from "react";
import sanityClient from "../../sanityClient";


export default function LogoPanel() {
 const [logo, setLogo] = useState('')
  useEffect(() => {
    sanityClient
      .fetch(
				`*[_type == "homePage" && title == "Logo"]{
            "imageUrl": image.asset->url
        }`
			)
      .then((data) => data.map((d: any) => {
        return setLogo(d.imageUrl);
      }))
			.catch(console.error);
  },[])


  return (
      <>
          <span className={`flex h-40 w-40 md:h-56 md:w-56 lg:w-56 lg:h-56`}>
            <img height={'100%'} width={'100%'} src={logo} alt="Church_Prayer_logo" />
        </span>
      </>
  )
}
