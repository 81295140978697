import React, { useEffect, useState } from 'react'
import sanityClient from '../sanityClient';
import { ServiceDetail } from '../models/ServiceDetail';
import InfoPanel from '../components/InfoPanel';

export default function Services() {
    const [backgroundImage, setBackgroundImage] = useState('')
    const [title, setTitle] = useState('');
    const [services, setServices] = useState([] as ServiceDetail[])
    useEffect(() => {
      sanityClient
      .fetch(
				`*[_type == "servicesPage" && title == "Services"]{
            title,
            "backgroundImage" : backgroundImage.asset->url
        }`
			)
      .then((data) => data.map((d: any) => {
        setTitle(d.title)
        setBackgroundImage(d.backgroundImage)
        return getServices();
      }))
			.catch(console.error);
    }, [])

    const getServices = () => {
      sanityClient
        .fetch(
	  			`*[_type == "service"]{
            "heading" : title,
            description,
            contactName,
            contactNumber
          }`)
        .then((data) => {
          setServices(data)
          return console.log(data)
        })
	  		.catch(console.error);
    }

  return (
    <span className="flex flex-col w-screen h-auto items-center justify-center">
      <span className='flex w-full h-full items-center justify-center'>
        <span className="flex justify-start items-center w-full h-96">
          <img className="flex object-cover h-full w-full lg:w-full lg:h-auto" src={backgroundImage} alt=""/>
        </span>
        <p className="flex h-auto absolute text-white font-bold text-6xl items-center justify-center text-center w-2/3 md:w-full">{ title }</p>
      </span>

      <span className='flex flex-col w-full h-full items-center justify-center bg-white text-center space-y-8 p-12 lg:p-20'>
        <span className="grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-3 gap-6 left-4">
        {
            services.map((ministry: ServiceDetail) => {
              return <InfoPanel key={ministry.id} heading={ministry.heading} description={ministry.description} />
          })  
        }
        </span>
      </span>
    </span>
  )
}
