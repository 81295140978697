
import { HiOutlineHome } from 'react-icons/hi'
import { BiChurch, BiPhoneCall } from 'react-icons/bi'
import { FaHandsHelping } from 'react-icons/fa'
import { MdEventNote } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import { ImInfo } from 'react-icons/im'
import {TiGroupOutline} from 'react-icons/ti'
import React, { Dispatch, SetStateAction } from 'react'
import { motion } from "framer-motion"


const staggerAnimation = {
  opacity: 1,
  x: 0,
  transition: {
    duration: 1, // Adjust duration as needed
    ease: [0.25, 0.1, 0.25, 1], // Customize the animation curve if desired
    
  },
};

interface NavigationListProps {
    setOpenNav: Dispatch<SetStateAction<boolean>>,
    openNav:boolean
}

export default function NavigationList({setOpenNav, openNav}:NavigationListProps) {
    const menus = [
        { name: 'Home', link: '/', icon: HiOutlineHome },
        { name: 'About Our Church', link: '/AboutOurChurch', icon: ImInfo },
        { name: 'Services', link: '/Services', icon: TiGroupOutline },
        { name: 'Events', link: '/Events', icon:  MdEventNote},
        { name: 'Tithe & Offering', link: '/Giving/TitheAndOffering', icon:  FaHandsHelping},
        { name: 'Ministries', link: '/Connect/Ministries', icon: BiChurch },
        { name: 'Contact Us', link: '/ContactUs', icon: BiPhoneCall }
        
    ]
  return (
        <span className='flex flex-col justify-center items-center align gap-y-6 py-56 w-full'>
        {
            menus.map((menu:any, index:number) => {
                return <motion.div
                    animate={staggerAnimation}
          initial={{ opacity: 0, x: -30, }} // Initial state
          variants={{
            // Add variants for hover/focus if needed
          }}
                    key={menu.name} onClick={() => setOpenNav(false)} >
                        <NavLink className="group flex flex-row gap-x-6 items-center text-right text-xl text-white" to={menu.link}>
                        <div className={`whitespace-pre duration-500`}>
                                {menu.name}
                            </div>
                    </NavLink>
                    
                </motion.div>
            })
        }
        </span>
  )
}
