export default function InfoPanel(props:any) {
    const {heading, description, contactName, contactNumber} = props;
  return (
        <span className="flex flex-col h-72 w-full items-center justify-center bg-sky-700 text-white p-8 rounded-md shadow-md hover:scale-105 hover:cursor-pointer">
          <p className="font-bold text-2xl">{heading}</p>
          <p>{contactName}</p>
          {contactNumber &&
          <p>0{contactNumber}</p>
          }
          <p>{description}</p>
        </span>
  )
}